* {
  font-family: "helvetica";
}

/* html, body {
  height: 100%
}

body {
  margin: 0px;
} */

/******************************* Body Basic Setup *************************************************/
div#root {
  height: 100%;
  width: 100%;
}
body.main-pages {
  background-color: #e6e6e6;
}
div.main-page {
  /* background-color: #e6e6e6; */
  width: 100%;
  height: 100%;
}
div.exam-pages {
  background: #dbe5f5;
  width: 100%;
  height: 100%;
  overflow: auto;
}

body.exam-pages {
  font-family: Arial, Helvetica, sans-serif;
  background: #dbe5f5;
  height: 100%;
  width: 100%;
}

a {
  cursor: pointer;
  text-decoration: none;
}

/******************************* Cards ***********************************************/
.card {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 10px;
  border-radius: 3px;
}

.card-login {
  max-width: 1066px;
  min-height: 494px;
  width: 80%;
  height: 50%;
  /* background: #F2771C; */
  background: linear-gradient(to bottom right, #f2771c 0%, #f4ae34 100%);
  /* background: -moz-linear-gradient(156deg, rgba(242,113,28,1) 0%, rgba(242,113,28,1) 35%, rgba(244,174,52,1) 100%);
  background: -webkit-linear-gradient(156deg, rgba(242,113,28,1) 0%, rgba(242,113,28,1) 35%, rgba(244,174,52,1) 100%);
  background: linear-gradient(156deg, rgba(242,113,28,1) 0%, rgba(242,113,28,1) 35%, rgba(244,174,52,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f2711c",endColorstr="#f4ae34",GradientType=1);  */
}

/* card content alignment */
.card-col {
  float: left;
  width: 100%;
  height: 100%;
  text-align: center;
}

/* card background for personal page */
.card-my-info {
  width: 1572px;
  height: 628px;
  background: #ffffff;
  color: #727272;
  text-align: center;
  font-size: 35px;
}

/* card background for personal info page */
.card-my-info .card-col {
  float: left;
  width: 100%;
  font-size: 16px;
  text-align: center;
}

/* For Login Screen */
div.card-logo {
  width: 266px;
  margin: 60px auto 24px auto;
}

p.card-header {
  font-size: 35px;
  color: #fff;
}

/* card for my home page */
.card-home {
  /*top: 77px; /* to compensate menu */
  width: 80%;
  height: 60%;
  background: #ffffff;
  color: #727272;
  text-align: center;
  font-size: 35px;
  display: flex;
  align-items: center;
}

/* card background for home page */
.card-home .card-col {
  float: left;
  width: 100%;
  font-size: 16px;
  text-align: center;
}

/* For home screen */
.card-home button {
  height: 200px;
  width: 200px;
  border: none;
  border-radius: 16px;
}

.card-home button#btn-my_info {
  background-image: url(../img/btn_my_info.png);
  background-size: cover;
}
.card-home button#btn-recent_exam {
  background-image: url(../img/btn_recent_exam.png);
  background-size: cover;
}
.card-home button#btn-exam {
  background-image: url(../img/btn_exam.png);
  background-size: cover;
}
.card-home button#btn-mock_exam {
  background-image: url(../img/btn_mock_exam.png);
  background-size: cover;
}

.card-home button:hover {
  cursor: pointer;
  -moz-box-shadow: 2px 2px 3px 3px #ccc;
  -webkit-box-shadow: 2px 2px 3px 3px #ccc;
  box-shadow: 2px 2px 3px 3px #ccc;
  border: none;
  opacity: 0.8;
  filter: alpha(opacity=80); /* For IE8 and earlier */
}

/******************************* Recent Page *******************************/
/* card for Recent Exam page */
.card-recent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 10px;
  border-radius: 3px;
  width: 1865px;
  height: 690px;
  background: #ffffff;
  color: #727272;
  text-align: center;
  font-size: 35px;
}

/* For Recent Exam Heading */
.card-recent > p {
  margin-top: 0px;
  margin-bottom: 0px;
}

/******************************* Error Page *******************************/
/* card for my ERROR page */
.card-error {
  /* width: 1572px;
  height: 628px; */
  color: #ffffff;
  text-align: center;
}

/* ERROR page: 404 */
.card-error p.error404 {
  margin: 0;
  font-size: 160px;
  height: 160px;
}

/* ERROR page: 404 */
.card-error p.error404 > img {
  /* margin-left: 0;
  margin-right: 0;
  margin-bottom: -5px; */
}

/* Margin alignment */
.m-0 {
  margin: 0;
}

/* Font Wieght */
.r-thin {
  font-weight: 200;
}
.r-light {
  font-weight: 300;
}
.r-reg {
  font-weight: 400;
}
.r-med {
  font-weight: 500;
}
.r-bold {
  font-weight: 700;
}
.r-black {
  font-weight: 900;
}

/* Font Size */
.f-15 {
  font-size: 15px;
}
.f-20 {
  font-size: 1.5rem;
}
.f-25 {
  font-size: 25px;
}
.f-30 {
  font-size: 30px;
}
.f-50 {
  font-size: 50px;
}

/* Font Color */
.f-red {
  color: #ff1010;
}
.f-green {
  color: #009a0a;
}

/******************************* Login Page *******************************/
.card h2 {
  color: white;
  font-weight: 300;
  text-align: center;
}

.login-form {
  margin-left: 80px;
  margin-right: 80px;
}

.form-input_holder {
  text-align: left;
  color: #ffffff;
  background-color: transparent;
  padding: 10px;
  margin-bottom: 20px;
}

.form-input_holder > label {
  position: absolute;
  z-index: 1;
  padding-bottom: 10px;
}

.form-input_holder i {
  font-size: 20px;
  width: 350px;
  padding-left: 5px;
}

.form-input_holder > input {
  position: absolute;
  z-index: 2;
  margin-left: 35px;
  width: 300px;
  float: right;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  outline: none;
}

.form-input_holder > input::placeholder {
  /* Firefox, Chrome, Opera */
  color: rgba(255, 255, 255, 0.8);
}
.form-input_holder > input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.8);
}
.form-input_holder > input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(255, 255, 255, 0.8);
}

label#input_holder_1,
label#input_holder_2 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

#username:active + label#input_holder_1,
#username:focus + label#input_holder_1 {
  border-bottom: none;
}

#password:active + label#input_holder_2,
#password:focus + label#input_holder_2 {
  border-bottom: none;
}

/* Button in login page */
.btn-orange {
  background: #f45734;
  color: white;
  border: none;
  border-radius: 100px;
  font-size: 25px;
  padding-top: 7px;
  padding-bottom: 7px;
  width: 50%;
  height: 44px;
}

/* Button in login page: Hover State */
.btn-orange:hover {
  background-color: white;
  color: rgba(0, 0, 0, 0.637);
  cursor: pointer;
}

/* Button in error page to return to Home Page */
.btn-error {
  background: rgb(244, 161, 52);
  background: linear-gradient(
    275deg,
    rgba(244, 161, 52, 1) 0%,
    rgba(242, 143, 28, 1) 21%,
    rgba(242, 119, 28, 1) 100%
  );
  border: none;
  border-radius: 100px;
  color: #ffffff;
  font-size: 25px;
  padding: 7px 30px;
  height: 44px;
}

/* State when hovered  */
.btn-error:hover {
  background: #f45734;
  -moz-box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.login-extra {
  margin-top: 2rem;
  color: white;
  font-size: 15px;
  font-weight: 300;
  text-align: center;
}

.login-extra > div {
  padding-bottom: 5px;
}

a.login-links {
  color: #7d7d7d;
  font-weight: 400;
  text-decoration: none;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
}

a.login-links:hover {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid white;
  outline: none;
}

/* Top Menu */
img.logo_center {
  width: 30px;
}

/****************************************TOP NAVIGATION *****************************/
/* Add a black background color to the top navigation */
.top-nav {
  position: relative;
  /*background-color: #F2771C;*/
  background: rgb(244, 161, 52);
  background: linear-gradient(
    180deg,
    rgba(244, 161, 52, 1) 0%,
    rgba(242, 143, 28, 1) 21%,
    rgba(242, 119, 28, 1) 100%
  );
  overflow: hidden;
  font-size: 1.25rem;
  color: #ffffff;
}

/* Style the links inside the navigation bar */
.top-nav a {
  float: left;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
}

/* Change the color of links on hover */
.top-nav a:hover {
  color: #f45734;
}

/* Add a color to the active/current link */
.top-nav a.active {
  color: white;
}

/* Centered section inside the top navigation */
.top-nav_center a {
  float: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Left-aligned section inside the top navigation */
.top-nav_left {
  margin-left: 20px;
}

.top-nav_left p {
  padding-left: 40px;
}

/* Right-aligned section inside the top navigation */
.top-nav_right {
  float: right;
  margin-top: -65px;
}

.top-nav_right a {
  margin-top: 28px;
  margin-right: 20px;
}

/* Responsive navigation menu - display links on top of each other instead of next to each other (for mobile devices) */
/*@media screen and (max-width: 600px) {
  .top-nav a, .top-nav_right {
    float: none;
    display: block;
  }

  .top-nav_center a {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
}*/

/************************* Side Navigation *********************************/
/* Dark overlay when sidenav is open */
#overlay {
  /*height: 100%; /* 100% Full-height */
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 - 100% Full-width with JS */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: rgba(0, 0, 0, 0.41); /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: width 0.1s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The side navigation menu */
.side-nav {
  /* hide nave on load */
  height: 100%; /* 100% Full-height */
  width: 0px; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 2; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #5a5a5a; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: width 0.2s; /* 0.2 second transition effect to slide in the sidenav */
}

/* For the Close button part of side nav */
.side-nav-close {
  top: 0;
  left: 0;
  margin-top: -59px;
  height: 2.8rem;
  background: #ffffff;
}

/* Position and style the close button (top right corner) */
.side-nav .closebtn {
  color: #f2771c;
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 2.5rem;
  margin-top: -5px;
  margin-left: 50px;
}

/* The navigation menu links */
.side-nav a {
  padding: 5px 8px 5px 32px;
  text-decoration: none;
  font-size: 1.2rem;
  color: #ffffff;
  display: block;
  transition: 0.4s;
}

/* Set format to side nav icons */
.side-nav i {
  text-align: center;
  width: 20px;
  margin-right: 12px;
}

/* When you mouse over the navigation links, change their color */
.side-nav a:hover,
#side-nav .active {
  color: #f2771c;
}

/* Set position and size of User profile info */
.side-nav-profile {
  text-align: center;
  width: 100%;
  color: #ffffff;
}

/* User profile image format (size and border) */
#profile_pic {
  left: 0;
  right: 0;
  margin: 20px auto auto auto;
  width: 5rem;
  height: 5rem;
  border: 6px solid #ffffff;
  border-radius: 100%;
}

.side-nav-list span {
  padding: 5px 8px 5px 32px;
  text-decoration: none;
  font-size: 1.2rem;
  color: #ffffff;
  display: block;
  transition: 0.4s;
}

.sub-menu {
  margin-left: 50px;
}

.sub-menu p {
  font-size: 15px;
  color: #ccc;
  margin-top: 0px;
  margin-left: 30px;
}
/**********************************Exam Side Nav ****************************/
/* Dark overlay when sidenav is open */
#exam-overlay {
  /*height: 100%; /* 100% Full-height */
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 - 100% Full-width with JS */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 55px; /* Stay at the top */
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */

  /*transition: 0s; /* 0.5 second transition effect to slide in the sidenav */
  -webkit-transition: 0.2s ease-in-out; /* Safari prior 6.1 */
  transition: 0.2s ease-in-out;
}

/* The side navigation menu */
.exam-side-nav {
  /*display: none;*/ /* hide nave on load */
  height: 100%; /* 100% Full-height */
  width: 0px; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 2; /* Stay on top */
  top: 55px; /* Stay at the top */
  left: 0;
  border: none;
  background-color: #ffffff;
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.2s; /* 0.2 second transition effect to slide in the sidenav */
}

/* For the Close button part of side nav */
.exam-side-nav-close {
  top: 0;
  left: 0;
  border-bottom: 0.5px solid rgba(89, 89, 89, 0.2);
}

/* Position and style the close button (top right corner) */
.exam-side-nav .closebtn {
  color: #3a3a3a;
  position: absolute;
  top: 0;
  right: 20px;
  font-size: 50px;
  margin-top: -10px;
  margin-left: 50px;
}

/* The navigation menu links */
.exam-side-nav a {
  font-size: 1.25rem;
  padding: 5px 8px 5px 32px;
  text-decoration: none;
  color: #3a3a3a;
  display: block;
}

.show {
  opacity: 1;
  transition: opacity 1000ms;
}

.hide {
  opacity: 0;
  transition: opacity 0ms;
}

.hide a {
  display: none;
}

/* When you mouse over the navigation links, change their color */
.exam-side-nav .active {
  color: #ffffff;
  background: linear-gradient(90deg, #1488cc 0%, #6aade4 100%);
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left 0.5s;
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
/* @media screen and (max-height: 450px) {
.sidenav {padding-top: 15px;}
.sidenav a {font-size: 18px;}
}  */

/********************************* Home Page *****************************************************/
.btn-exam_group {
  display: none;
  cursor: pointer;
  margin: auto;
  height: 200px;
  width: 200px;
  background: #ec6a0a; /* Dark Orange */
  border: none;
  border-radius: 16px;
  -moz-box-shadow: 2px 2px 3px 3px #ccc;
  -webkit-box-shadow: 2px 2px 3px 3px #ccc;
  box-shadow: 2px 2px 3px 3px #ccc;
}

/* Set links height */
.btn-exam_group a {
  display: inline-block;
  height: 25%;
  width: 100%;
  text-decoration: none;
  line-height: 50px;
  text-align: left;
  /* padding-left: 30px; */
  color: #ffffff;
}

.btn-exam_group a:hover,
.btn-exam_group a:active,
.btn-exam_group a:focus {
  background: #f0883b; /* Faded Orange */
  text-decoration: none;
}

/* Set border for links */
.btn-exam_group a:first-child {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

/* Set border for links */
.btn-exam_group a:nth-child(4) {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

/********************************* Footer ******************************************************/
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 45px;
  background: linear-gradient(to bottom right, #f2771c 0%, #f4ae34 100%);
  color: white;
  text-align: center;
}
.footer p {
  margin-top: 10px;
}

/********************************* Recent Exam Page *************************************/
/* table */
/* table, th, td {
  /* border: none;
  border-collapse: collapse;
} */

/* th, td {
  padding: 5px 0 5px 0;
} */

table.table-recent {
  font-size: 1.125rem;
  width: 100%;
}

table.table-recent > tr > td {
  padding: 0.4rem;
  display: block;
}

table.table-recent > tr > td > span {
  display: inline-block;
  float: left;
  color: rgb(20, 136, 204);
  font-weight: 550;
}

table.table-recent > tr > td > p {
  display: inline-block;
}

table.table-recent > tr:nth-child(odd) {
  background-color: #e9e9e9;
}

tr.bg_grey {
  background-color: #e9e9e9;
}

/* Pagination */
.pagination {
  /* float: right; */
  font-size: 0;
}

.pagination > button {
  height: 30px;
  width: 30px;
  color: #707070;
  background: #ffffff;
  border: 0.5px solid #707070;
  text-align: center;
  margin-top: 25px;
}

.pagination button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.pagination button:hover {
  cursor: pointer;
  background: #f2771c;
  border-color: #f2771c;
  color: #ffffff;
}

.btn-review {
  font-size: 0px;
  margin: 0px;
  border: none;
  background: none;
  padding: 0;
}

.btn-review:hover {
  cursor: pointer;
  cursor: pointer;
  -moz-box-shadow: 2px 2px 3px 3px rgb(0, 0, 0, 0.03);
  -webkit-box-shadow: 2px 2px 3px 3px rgb(0, 0, 0, 0.03);
  box-shadow: 2px 2px 3px 3px rgb(0, 0, 0, 0.03);
  border: none;
  opacity: 0.8;
  filter: alpha(opacity=80); /* For IE8 and earlier */
}

.btn-review_group {
  float: left;
  height: 25px;
  width: 140px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.btn-review_icon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  float: left;
  height: 27px;
  width: 30px;
  padding: 3px 5px 0px 5px;
  background-color: #0d6eba;
}

.btn-review_label {
  border-radius: 5px;
  height: 27px;
  padding-top: 3px;
  background: #008bf7;
}

/* #password:active + label#input_holder_2, #password:focus + label#input_holder_2 {
  border-bottom: none;
} */

/************************************ Exams creation page ******************************/
.card-exam_creation_page {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 23px;
  border-radius: 3px;
  width: 1830px;
  height: 720px;
  background: #ffffff;
  color: #3a3a3a;
  text-align: left;
}

.card-exam_creation_page > p:first-child {
  margin: 0px;
}

/* .form-input_holder > input::placeholder { color: rgba(255,255,255,.8); }
.form-input_holder > input:-ms-input-placeholder { color: rgba(255,255,255,.8); }
.form-input_holder > input::-ms-input-placeholder { color: rgba(255,255,255,.8); } */
.input-exam_name {
  margin-top: 20px;
}

.input-exam_name > input {
  position: absolute;
  margin-top: 5px;
  margin-left: 200px;
  padding-left: 10px;
  padding-bottom: 10px;
  width: 300px;
  float: right;
  background: transparent;
  border: none;
  color: #3a3a3a;
  font-size: 20px;
  font-weight: 300;
  outline: none;
  border-bottom: 1px solid #727272;
}

#exam_name:active,
#exam_name:focus {
  border: none;
}

.input-exam_name button {
  margin-top: -10px;
  float: right;
  height: 44px;
  width: 347px;
  border: none;
  border-radius: 20px;
  background-color: #009a0a;
  color: #ffffff;
  font-size: 25px;
  font-weight: 300;
}

.input-exam_name button:hover {
  opacity: 0.8;
  cursor: pointer;
  -moz-box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
}

.t-list {
  border-top: 1px solid rgba(35, 31, 32, 0.16); /* border-color */
}

.t-list_row {
  height: 50px;
  border-bottom: 1px solid rgba(35, 31, 32, 0.16); /* border-color */
}

.t-list_col {
  height: 40px;
  margin: 5px;
  float: left;
  text-align: center;
  border-right: 1px solid rgba(35, 31, 32, 0.16); /* border-color */
  line-height: 40px;
  font-size: 16px;
  margin-left: 10px;
}

.t-list_col:last-child {
  border: none;
}

/* select starting stylings ------------------------------*/
.select {
  position: relative;
  width: 300px;
}

.select-text {
  position: relative;
  font-family: inherit;
  background-color: transparent;
  width: 100%;
  font-size: 16px;
  padding: 10px 10px 10px 0;
  border-radius: 0;
  border: none;
}

/* Use custom arrow */
.select .select-text {
  appearance: none;
  -webkit-appearance: none;
}

.select:after {
  position: absolute;
  top: 18px;
  right: 10px;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgba(0, 0, 0, 0.12);
  pointer-events: none;
}

/* active state */
.select-text:focus ~ .select-label,
.select-text:valid ~ .select-label {
  top: -20px;
  transition: 0.2s ease all;
  font-size: 14px;
  color: #ff1010;
}

.select-text option:hover {
  background: #eeeeee;
}

/* Switch */
.switch-input {
  display: none;
}
.switch-label {
  position: relative;
  display: inline-block;
  min-width: 112px;
  cursor: pointer;
  text-align: left;
  margin: 0px 16px 0px 16px;
  padding: 0px 0 0px 44px;
}
.switch-label:before,
.switch-label:after {
  content: "";
  position: absolute;
  margin: 0;
  outline: 0;
  top: 50%;
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.switch-label:before {
  left: 1px;
  width: 34px;
  height: 14px;
  background-color: #9e9e9e;
  border-radius: 8px;
}
.switch-label:after {
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fafafa;
  border-radius: 50%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14),
    0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
}
.switch-input:checked + .switch-label:before {
  background-color: #f4ae34;
}
.switch-input:checked + .switch-label:after {
  background-color: #f2771c;
  -ms-transform: translate(80%, -50%);
  -webkit-transform: translate(80%, -50%);
  transform: translate(80%, -50%);
}

.btn-t-row_add {
  background: #009a0a;
  color: #ffffff;
  border: none;
  border-radius: 100%;
  height: 40px;
  width: 40px;
}

.btn-t-row_remove {
  background: #ff0000;
  color: #ffffff;
  border: none;
  border-radius: 100%;
  height: 40px;
  width: 40px;
}

button.btn-t-row_remove:hover,
button.btn-t-row_add:hover {
  cursor: pointer;
  opacity: 0.8;
  -moz-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}

/********************** Loading overlay when redirecting to Page ******************/
#load-overlay {
  /* display: none; */
  height: 100%; /* 100% Full-height */
  width: 0%; /* 0 - 100% Full-width with JS */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  color: white;
}

.load-overlay_body {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 300px;
  width: 500px;
  margin: auto;
  padding: 10px;
  border-radius: 3px;
  text-align: center;
}

#myProgress {
  margin: 20px auto 8px auto;
  height: 17px;
  width: 256px;
  background-color: rgba(255, 255, 255, 0.17);
  border-radius: 10px;
  overflow: hidden;
}

#myBar {
  width: 1%;
  height: 17px;
  background-color: white;
  border-radius: 10px;
}

/* exam top navigation */
.exam-pages .top-nav {
  position: relative;
  background: linear-gradient(180deg, #1488cc 0%, #3398d3 21%, #6aade4 100%);
  height: 55px;
  width: 100%;
  overflow: hidden;
  font-size: 20px;
  color: #ffeac5;
  font-weight: 700;
}

.exam-pages .top-nav > p {
  height: 55px;
  margin: 0px 0px 0px 20px;
  line-height: 55px;
}

.exam-pages .main {
  position: absolute;
  left: 0;
  right: 0;
  top: 55px;
  bottom: 0;
  margin: auto;
  padding: 16px;
  border: 1px solid green;
  display: flex;
  align-items: stretch;
}

/*******************************************  ************************************/
.main-container {
  display: flex;
  margin: -75px 20px 20px 20px;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

.header-new-ui {
  display: flex;
  flex-direction: row;
  margin-top: 87px;
}

.header-menu-new-ui a {
  color: #1488cc;
  font-size: 38px;
}

.header-description {
  width: 100%;
  margin-left: 20px;
  margin-bottom: 20px;
  padding: 5px 10px 5px 10px;
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  background: #f5f8ff;
  border-radius: 5px;
  -moz-box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
}

.header-menu-new-ui a:hover {
  color: #0370a7;
}
.content-new-ui {
  display: flex;
  flex-direction: column;
  order: 2;
  width: 100%;
  height: 100%;
  background: #f5f8ff;
  border-radius: 5px;
  -moz-box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.content-single {
  /*margin: 15px;*/
  margin: 20px 20px 20px 40px;
  height: 100%;
  font-size: 16px;
  text-align: justify;
}

.content-split {
  display: flex;
  margin: 0.5rem;
  height: 100%;
  overflow-y: auto;
  /* font-size: 1rem; */
  /* text-align: justify; */
}
.content-left {
  margin-right: 10px;
  margin-left: 20px;
  flex-basis: 50%;
  width: 50%;
}

.content-left img {
  /* display: block;
  width: 80%;
  margin-left: auto;
  margin-right: auto; */
}

.content-right {
  flex-basis: 50%;
  margin-right: 0px;
  margin-left: 10px;
}

.content-right > h2 {
  text-align: center;
}

.content-single::-webkit-scrollbar {
  width: 10px;
}

.content-single::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: white;
  border-radius: 20px;
  border: none;
}

.content-single::-webkit-scrollbar-thumb {
  background-color: #1488cc;
  border-radius: 20px;
}

.content-button {
  /* text-align: right; */
  height: 35px;
  margin: 15px;
}

.content-button button {
  margin-left: 8px;
  border: 1px solid rgba(112, 112, 112, 0.5);
  border-radius: 20px;
  padding: 5px 20px 5px 20px;
}

.content-button button:hover {
  cursor: pointer;
  background-color: #9e9e9e;
  -moz-box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
}

.question {
  margin-left: 15px;
}

/**************************** List ********************************/
ul.input-list {
  list-style-type: none;
}
ul.input-list li {
  margin-bottom: 15px;
}

ul.input-list input {
  position: relative;
  margin-left: 20px;
  width: 300px;
  background: transparent;
  border: none;
  color: #3a3a3a;
  outline: none;
  border-bottom: 1px solid #727272;
}

/***************************** Radio Button/Checkbox **************************/
.pure-checkbox input[type="checkbox"],
.pure-radiobutton input[type="checkbox"],
.pure-checkbox input[type="radio"],
.pure-radiobutton input[type="radio"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.pure-checkbox input[type="checkbox"]:focus + label:before,
.pure-radiobutton input[type="checkbox"]:focus + label:before,
.pure-checkbox input[type="radio"]:focus + label:before,
.pure-radiobutton input[type="radio"]:focus + label:before,
.pure-checkbox input[type="checkbox"]:hover + label:before,
.pure-radiobutton input[type="checkbox"]:hover + label:before,
.pure-checkbox input[type="radio"]:hover + label:before,
.pure-radiobutton input[type="radio"]:hover + label:before {
  border-color: #000000;
  background-color: #f2f2f2;
}

.pure-checkbox input[type="checkbox"]:active + label:before,
.pure-radiobutton input[type="checkbox"]:active + label:before,
.pure-checkbox input[type="radio"]:active + label:before,
.pure-radiobutton input[type="radio"]:active + label:before {
  transition-duration: 0s;
}

.pure-checkbox input[type="checkbox"] + label,
.pure-radiobutton input[type="checkbox"] + label,
.pure-checkbox input[type="radio"] + label,
.pure-radiobutton input[type="radio"] + label {
  position: relative;
  padding-left: 2em;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
}

.pure-checkbox input[type="checkbox"] + label:before,
.pure-radiobutton input[type="checkbox"] + label:before,
.pure-checkbox input[type="radio"] + label:before,
.pure-radiobutton input[type="radio"] + label:before {
  box-sizing: content-box;
  content: "";
  color: #000000;
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -9px;
  border: 2px solid #000000;
  text-align: center;
  transition: all 0.4s ease;
}

.pure-checkbox input[type="checkbox"] + label:after,
.pure-radiobutton input[type="checkbox"] + label:after,
.pure-checkbox input[type="radio"] + label:after,
.pure-radiobutton input[type="radio"] + label:after {
  box-sizing: content-box;
  content: "";
  background-color: #000000;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  transform: scale(0);
  transform-origin: 50%;
  transition: transform 200ms ease-out;
}

.pure-checkbox input[type="checkbox"]:disabled + label:before,
.pure-radiobutton input[type="checkbox"]:disabled + label:before,
.pure-checkbox input[type="radio"]:disabled + label:before,
.pure-radiobutton input[type="radio"]:disabled + label:before {
  border-color: #cccccc;
}

.pure-checkbox input[type="checkbox"]:disabled:focus + label:before,
.pure-radiobutton input[type="checkbox"]:disabled:focus + label:before,
.pure-checkbox input[type="radio"]:disabled:focus + label:before,
.pure-radiobutton input[type="radio"]:disabled:focus + label:before,
.pure-checkbox input[type="checkbox"]:disabled:hover + label:before,
.pure-radiobutton input[type="checkbox"]:disabled:hover + label:before,
.pure-checkbox input[type="radio"]:disabled:hover + label:before,
.pure-radiobutton input[type="radio"]:disabled:hover + label:before {
  background-color: inherit;
}

.pure-checkbox input[type="checkbox"]:disabled:checked + label:before,
.pure-radiobutton input[type="checkbox"]:disabled:checked + label:before,
.pure-checkbox input[type="radio"]:disabled:checked + label:before,
.pure-radiobutton input[type="radio"]:disabled:checked + label:before {
  background-color: #cccccc;
}

.pure-checkbox input[type="checkbox"] + label:after,
.pure-radiobutton input[type="checkbox"] + label:after {
  background-color: transparent;
  top: 50%;
  left: 4px;
  width: 8px;
  height: 3px;
  margin-top: -4px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 0 3px 3px;
  border-image: none;
  transform: rotate(-45deg) scale(0);
}

.pure-checkbox input[type="checkbox"]:checked + label:after,
.pure-radiobutton input[type="checkbox"]:checked + label:after {
  content: "";
  transform: rotate(-45deg) scale(1);
  transition: transform 200ms ease-out;
}

.pure-checkbox input[type="radio"]:checked + label:before,
.pure-radiobutton input[type="radio"]:checked + label:before {
  animation: borderscale 300ms ease-in;
  background-color: white;
}

.pure-checkbox input[type="radio"]:checked + label:after,
.pure-radiobutton input[type="radio"]:checked + label:after {
  transform: scale(1);
}

.pure-checkbox input[type="radio"] + label:before,
.pure-radiobutton input[type="radio"] + label:before,
.pure-checkbox input[type="radio"] + label:after,
.pure-radiobutton input[type="radio"] + label:after {
  border-radius: 50%;
}

.pure-checkbox input[type="checkbox"]:checked + label:before,
.pure-radiobutton input[type="checkbox"]:checked + label:before {
  animation: borderscale 200ms ease-in;
  background: #000;
}

.pure-checkbox input[type="checkbox"]:checked + label:after,
.pure-radiobutton input[type="checkbox"]:checked + label:after {
  transform: rotate(-45deg) scale(1);
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px #9e9e9e;
  }
}

/*************************************** Input Box *******************************/
.inputBox {
  position: relative;
  margin-left: 20px;
  width: 200px;
  color: #3a3a3a;
  height: 26px;
  background: #ffffff;
  border: 1px solid #0370a7;
  border-radius: 3px;
  text-align: center;
  font-size: 16px;
}

/************************************** Button Dragging ************************************/
.btn-drag {
  background: transparent;
  border: 1px solid #70707033;
  width: 500px;
  padding: 5px 5px 5px 20px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  -moz-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);
}

.btn-long {
  background-color: #e9e9e9;
  width: 100%;
  padding: 10px 10px 10px 20px;
}

.btn-drag:hover {
  cursor: pointer;
  background: #e6e6e6;
  border: 1px solid #70707011;
}

/************************************** Number List ****************************/
.num_list {
  margin: 5px 0px 5px 0px;
  width: 40px;
  float: left;
  font-weight: bold;
}

/********************************Accordion *******************************/
/* reset */
ul {
  /* margin: 0;
  padding: 0;
  list-style: none; */
}

input[type="radio"] {
  display: none;
}

/* style */
.a-container {
  width: 100%;
  margin: 20px auto;
}

.a-container > li > label {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 10px 20px;
  background-color: #e6e6e6;
  border: 1px solid #ddd;
  border-radius: 3px;
  -moz-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);

  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

/* For arrow carret
.a-container label:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid #aaa;
  border-right: 6px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 6px solid transparent;
  position: absolute;
  right: 10px;
  top: 16px;
}
*/

.a-container > li > input:checked + label,
.a-container > li > label:hover {
  background-color: #ffffff;
}

.a-container > li > input:checked + label:after {
  border-top: 8px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #222;
  border-left: 6px solid transparent;
  top: 6px;
}

.a-content {
  padding: 20px;
  display: none;
}

.a-container > li > input:checked ~ .a-content {
  display: block;
}

.a-items {
  margin-top: 15px;
}

/******************************* Text Area ******************************/
textarea.art {
  width: 100%;
  padding: 0.5rem;
  height: 65vh;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

/************************** Speaking ****************************/
.content-speaking-center {
  background: none;
  border: none;
}

.content-speaking-rec {
  margin: auto;
  background: #e6e6e6;
  border: none;
  border-radius: 5px;
  text-align: center;

  -moz-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.05);
}

.content-speaking-split {
  display: flex;
  /*  margin: 20px;
  height: 100%;
  overflow-y: hidden;
  font-size: 16px;
  text-align: justify;
*/
}
.content-speaking-left {
  margin-right: 10px;
  margin-left: 20px;
  flex-basis: 50%;
  width: 50%;
}

.content-speaking-right {
  flex-basis: 50%;
  margin-right: 20px;
  margin-left: 10px;
}

.content-speaking-right > h2 {
  text-align: center;
}

.content-speaking-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  color: white;
  text-align: center;
}

.img-rec {
  margin-left: -508px;
  margin-top: -68px;
  height: 44px;
}

.btn-rec {
  background: url(../img/rec.png);
  width: 44px;
  height: 44px;
  border: none;
}

.btn-rec:hover {
  opacity: 0.5;
  cursor: pointer;
}

.btn-repeat button {
  margin-top: 50px;
  margin-left: 8px;
  margin-bottom: 20px;
  border: 1px solid rgba(112, 112, 112, 0.5);
  border-radius: 20px;
  padding: 5px 20px 5px 20px;
  font-size: 20px;
}

.btn-repeat button:hover {
  cursor: pointer;
  background-color: #9e9e9e;
  -moz-box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
}

.center {
  text-align: center;
}

ul.dash {
  list-style: none;
  padding-left: 1em;
}
ul.dash > li:before {
  display: inline-block;
  content: "-";
  width: 1em;
  margin-left: 20px;
}
.m-t-2 {
  margin-top: 2rem;
}

.m-t-5 {
  margin-top: 5rem;
}
.m-t-1 > i {
  margin-top: 0.65rem;
}
.content-left::-webkit-scrollbar {
  width: 10px;
}
.content-left::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: white;
  border-radius: 20px;
  border: none;
}
.content-left::-webkit-scrollbar-thumb {
  background-color: #1488cc;
  border-radius: 20px;
}
img.responsive-img {
  width: 100%;
  height: auto;
}
p {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  line-height: normal !important;
}
body {
  line-height: normal !important;
}

.footer-links > a {
  color: white;
  font-size: 0.8rem;
}
.footer-links {
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}
.footer-links > a:hover {
  color: #4183c4;
}
.footer-trademark {
  margin-bottom: 0.2em !important;
  font-size: 0.95rem;
}
.home-header {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
  color: #727272 !important;
}
.mouseoverlist > a {
  font-size: 1.25rem;
}
.loader-new-ui {
  font-size: 3rem;
  margin-bottom: 1rem !important;
}
.error-404 {
  font-size: 320px !important;
  font-weight: 900 !important;
  margin: 0 !important;
  color: #ffffff !important;
}
.m-t-2 {
  margin-top: 2rem;
}
.m-t-5 {
  margin-top: 5rem;
}
.content-left::-webkit-scrollbar {
  width: 10px;
}
.content-left::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: white;
  border-radius: 20px;
  border: none;
}
.content-left::-webkit-scrollbar-thumb {
  background-color: #1488cc;
  border-radius: 20px;
}
.no-margin-404 {
  margin-top: 0.2em !important;
  margin-bottom: 0em !important;
}
.margin-bottom-404 {
  margin-bottom: 0.25em !important;
}
.rem5 {
  font-size: 2.5rem !important;
}
a {
  cursor: pointer !important;
}
.writing-new-ui-p {
  height: 55px;
  margin: 0px 0px 0px 20px !important;
  line-height: 55px !important;
}
i.cursor-pointer {
  cursor: pointer;
}
.content-right::-webkit-scrollbar-thumb {
  background-color: #1488cc;
  border-radius: 20px;
}
.content-right::-webkit-scrollbar-track {
  background-color: white;
}
.smaller-timer {
  font-weight: 500;
  font-size: 1.25rem;
}
button.active-art {
  background-color: #9e9e9e;
  box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: no-drop !important;
}
.no-margin-top-bottom {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.margin-top-3rem {
  margin-top: 3rem;
}
span.cursor-pointer {
  cursor: pointer;
}
.no-margin-p > div > p {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.listen-content-split {
  margin: 0.5rem;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  text-align: center;
  /* font-size: 1rem; */
  /* text-align: justify; */
}
.listen-content-split::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: white;
  border-radius: 20px;
  border: none;
}
.listen-content-split::-webkit-scrollbar-thumb {
  background-color: #1488cc;
  border-radius: 20px;
}

.paper-box {
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  background: #f5f8ff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin: 1rem 1rem 1rem 1rem;
  padding-top: 0.1rem;
  padding-bottom: 1.5rem;
}

.speaking-container {
  display: flex;
  margin: -75px 20px 20px 20px;
  flex-direction: column;
  align-items: stretch;
}

.recorder-bottom {
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  height: 35%;
  background-color: #d4d4d4;
}

.progress > .bar {
  height: 0.5rem !important;
  background-color: white !important;
  min-width: 0px !important;
}
i.interviewer-icon {
  font-size: 4.5rem;
}

.icon-button-microphone:hover {
  background: #e0e1e2 none !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.blinking > i {
  animation: blinker 1s linear infinite;
  color: red;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.speaking-stop-button {
  margin-left: 8px;
  border: 1px solid rgba(112, 112, 112, 0.5);
  border-radius: 20px;
  padding: 5px 20px 5px 20px;
}
.speaking-stop-button:focus {
  outline: none;
}
.speaking-stop-button:hover {
  cursor: pointer;
  background-color: #9e9e9e;
  box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.1);
}
audio:focus {
  outline: none !important;
}
.press-btn-red {
  margin-top: 3rem !important;
}
.blue-hover:hover {
  background: linear-gradient(90deg, #1488cc 0%, #6aade4 100%);
  color: white;
}
.no-margin-top {
  margin-top: 0px !important;
}
.review-font-blue {
  color: rgb(20, 136, 204);
}
span.exam-number:after {
  content: "\00a0\00a0\00a0\00a0";
}
span.exam-number2:after {
  content: "\00a0\00a0";
}
.read-mcq-radio body {
  background-color: inherit;
}
.blinking {
  animation: blinker 1s linear infinite;
}
.text-left {
  text-align: left;
}
.speaking-review-header {
  text-align: left;
  margin-left: 1rem;
}
.notes-text {
  width: 90%;
  min-width: 90%;
  max-width: 90%;
}
.finish-btn {
  margin-bottom: 3rem;
}
.full-height-width {
  width: 100%;
  height: 100%;
}
.review-notes-div {
  margin: 1rem;
}
.review-notes-text {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
.no-resize {
  resize: none;
}
.testing-review-group {
  padding: 1rem;
  margin-bottom: 1rem;
  background: #dddddd;
  margin: 1rem;
}
.learning-review-group {
  padding: 1rem;
  margin-bottom: 1rem;
  background: #ffa351;
  margin: 1rem;
}
.microphone3 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.visible {
  visibility: visible;
}
.hidden {
  visibility: hidden;
}
.zero-width {
  width: 0px;
  height: 0px;
}
.new-sound-wave {
  position: absolute;
  margin-left: 1.5rem;
}
.new-sound-wave2 {
  position: relative;
}

.testing-area-speaking {
  background-color: #ddd;
  padding: 1rem;
}

.learning-area-speaking {
  background-color: #ffa351;
  padding: 1rem;
  margin-top: 1rem;
}

.model-answer-writing {
  background-color: white;
  padding: 1rem;
}

.full-max-width {
  width: 100%;
}
