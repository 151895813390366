

#components-form-demo-normal-login {
  max-width: 400px !important;
}
.login-form {
  max-width: 400px !important;
}
#components-form-demo-normal-login{
  float: right !important;
}
.login-form-forgot{
  float: right !important;
}
#components-form-demo-normal-login {
  width: 100% !important;
}
.login-form-button {
  margin-top:5px;
  width: 100% !important;
}
.text-center{
  text-align:center !important;
}
.login {
  margin: auto;
  margin-top: 15px;
  width: 400px;
  padding: 30px 25px;
  background: white;
  border: 1px solid #c4c4c4;
}
#components-layout-demo-top {
  width: 120px;
  height: 31px;
  background: rgba(255,255,255,.2);
  margin: 16px 24px 16px 0;
  float: left;
}
.logo-left{
  width: 250px;
  height: 31px;
  float: left;
}
.logo-left > h2{
  color: white;
}
.logo-right{
  width: 250px;
  height: 31px;
  float: right;
  color: white;
  text-align: right;
}
.logo-right > a > h3{
  margin-right: 50px;
  color: white;
}
.text-right{
  text-align:right;
}
.margin-right-20{
  margin-right:20px
}
.gray-timer{
  color:#8c8c8c;
}
.margin-top-5{
  margin-top:5px;
}
.margin-top-15{
  margin-top: 15px;
}
.margin-top-30{
  margin-top: 30px;
}
.content-container{
  padding: 16px 16px;
  min-height: 85vh;
  height:100%;
}
.main-content{
  background: #fff;
  padding: 24px;
  min-height: 280px;
  height: 100%;
  width: 95%;
  margin: auto;
  position:relative;
}
hr {
  -moz-border-bottom-colors: none;
  -moz-border-image: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #EEEEEE -moz-use-text-color #FFFFFF;
  border-style: solid none;
  border-width: 1px 0;
  margin: 18px 0;
}
hr.colored {
  border: 0;   /* in order to override TWBS stylesheet */
  height: 5px;
  background: -moz-linear-gradient(left, #002766, #bfbfbf 12.5%);  /* FF3.6+ */
  background: -webkit-linear-gradient(left,#002766, #bfbfbf 12.5%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #002766, #bfbfbf 12.5%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #002766, #bfbfbf 12.5%); /* IE10+ */
  background: linear-gradient(to right, #002766, #bfbfbf 12.5%); /* W3C */
}
.line{
  width:100%;
}
.color-1{
  background:#0081A4;
  display:block;
  float:left;
  height:5px;
}
.color-2{
  background:#e8e8e8;
  display:block;
  float:left;
  height:5px;
}
.header-volcano{
  background: #0F82B4;
}
p.instruction{
  font-weight: bold;
  font-style: italic;
  color: #8c8c8c;
}
p.countdown{
  color: #cf1322;
}
.padding-10{
  padding:10px;
}
.width-25{
  width:50%;
}
.soundwave{
  max-width:80%
}
.graybox{
  width:60%;
  border: 2px solid #e8e8e8;
  margin: auto;
  max-width: 350px;
}
.margin-top-10{
  margin-top:10px;
}
p.content{
  color: #8c8c8c;
}
.next-btn{
  background-color: #0081A4;
  color: white;
}
.next-btn:hover{
  background-color: rgba(0, 128, 164, 0.719);
  color: white;
}
.next-btn:focus{
  background-color: rgb(11, 153, 192);
  color: white;
}
.text-left{
  text-align:left;
}
.text-overflow{
  background-color: #ffccc7;
}
span.word{
  display: inline-block;
  margin-right:5px;
}
span.word:hover{
  cursor: pointer;
}
span.word-selected{
  background-color:yellow;
}
.long-ass-label > .ant-form-item-label {
  text-align: left;
  white-space: normal;
  line-height: 21px;
}
.margin-left-75{
  margin-left: 75px;
}
.padding-15{
  padding: 15px;
}
.padding-right-45{
  padding-right: 45px;
}
.wrap-label{
  white-space: normal;
  width: 100%;
}
.no-padding-top{
  padding-top:0px;
}
.padding-top-15{
  padding-top:15px;
}
.margin-5{
  margin: 5px;
}
.bg-blue{
  background-color: #002766;
  color: white;
}
.bg-red{
  background-color: #cf1322;
  color: white;
}
.selected > span:nth-child(2){
  background-color: #fff1b8;
}
.selected-gold > label > span:nth-child(2){
  background-color: #fff1b8;
}
.sentence-container{
  border: solid 1px #666666;
  max-height: 1000px;
}
.sentence-container-title{
  background-color: #8c8c8c;
}
.white-font{
  color:white;
}
.padding-top-4{
  padding-top:4px;
}
.padding{
  padding:4px;
}
.sentence{
  text-align: left !important;
  border: solid 1px #686868;
  color: #686868;
  cursor: all-scroll;
}
.sentence:hover{
  text-align: left !important;
  border: solid 1px #8c8c8c;
  color: #8c8c8c;
}
.sentence > p{
  padding-top:8px;
  margin-left:15px;
}
.sentence-clicked{
  border: solid 1px #40a9ff;
}
.sentence-clicked:hover{
  border: solid 1px #40a9ff;
}
.horizontal-btn{
    height:100%;
    /* Just set up the container element to use flexbox */
    display:flex;
    justify-content:center;
    align-items:center;
}
div.middle{
  display: inline-block;
}
.sentence-icon{
  margin-top: 7px;
  margin-right:5px
}
.answer{
  min-height:30px;
}
.height-50{
  margin: auto;
  height:50%;
}
.full-width{
  width:100%;
}
.ddcontainer{
  width:100%!important;
}
.ddcontainer > span{
  width:100%!important;
}
.bottom{position:absolute; bottom:0;}
.padding-top-50{
  padding-top:50px
}
.padding-top-150{
  padding-top:25px;
}
.gray-border{
  border: solid 2px #f5f5f5;
  color: #8c8c8c;
}
.height-80{
  height:80%;
}
.height-20{
  height:20%;
}
.fill-blank-container{
  background-color: #bfbfbf;
}
.fill-blank-content{
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  line-height:25pt;
  font-style: italic;
  word-spacing: 2px;
}
.fill-blank-box{
  margin-top:3px;
  display: inline-block;
  border: dotted 3px #d9d9d9;
  width:150px;
  height:70%;
  margin-top:3px;
  text-align: center;
}
div.fill-blank-answer{
  background-color: white;
  border: dotted 1px #d9d9d9;
  font-style: italic;
  margin:5px;
  height:28px;
}
.width-150{
  width:150px;
  text-align:center;
  text-align-last:center;
}
.fill-select-content{
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-right: 20px;
  line-height:25pt;
  word-spacing: 2px;
}
.fill-in-input{
  width:200px;
  text-align:center;
}
.width-80{
  width: 80%;
}
.margin-bottom-zero{
  margin-bottom: 0px;
}
.record-circle {
  height: 14px;
  width: 14px;
  color: #cf1322;
  border-radius: 50%;
  display: inline-block;
}
.invisible {
  height: 1px !important;
  width: 1px !important;
  /* position: absolute;
  left: -9999px; */
}
span.essay-timer{
  color: #cf1322;
}
hr.divider{
  height: 1px;
  color: #d9d9d9;
  background-color: #d9d9d9;
  border: none;
}
button.welcome1{
  width:100px;
  height:40px;
}
.fill-blank-answer:hover{
  border: 2px solid #595959;
}
.fill-blank-box:hover{
  border: 2px solid #595959;
}
.fill-blank-box-black{
  border: 2px solid #595959;
}
button.copy-cut-paste{
  width:100px;
}
.min-height-50{
  min-height: 55px;
}
#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  background: rgba(255,255,255,.2);
  margin: 16px 28px 16px 0;
  float: left;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255,255,255,.2);
  margin: 16px;
}

.logo-right > h3{
  margin-right: 50px;
  color: white;
}

.login-error {
  margin: auto;
  margin-top: 15px;
  width: 400px;
  padding: 30px 25px;
}

.padding-top-50{
  padding-top: 65px;
}
.padding-top-10{
  padding-top:10px;
}
.border-right{
  border-right: 1px solid #c4c4c4;
}
.mcq-wrong {
  color:red;
  text-decoration:line-through;
}

.mcq-correct > span:nth-child(2){
  background-color: #98FB98;
}

.selected-mcq-wrong > label > span:nth-child(2){
  color:red;
  text-decoration:line-through;
}

.selected-mcq-correct > label > span:nth-child(2){
  background-color: #98FB98;
}

span.word-correct{
  background-color: #98FB98;
}

span.word-wrong{
  color:red;
  text-decoration:line-through;
}

p.margin-zero > p {
  margin: 0!important;
}

.margin-zero > p {
  margin: 0!important;
}
.padding-20{
  padding: 20px;
}
.width-50-percent{
  width:50%;
}
.margin-bottom-10{
  margin-bottom:10px;
}
.learning-strat-nav{
  background: #fff;
  max-height: 500px;
  overflow-y: auto;
  padding: 24px;
  height: 100%;
  width: 100%;
  margin: auto;
  position:relative;
  margin-right: -10px!important;
}
.no-border-color{
  border: none!important;
}
.no-pointer{
  pointer-events: none
}
.mic-test-soundwave{
  max-width:50%;
  border: solid 1px black;
  margin: 15px;
}
.margin-top-200{
  margin-top: 200px!important;
}
.margin-top-50{
  margin-top: 50px!important;
}
.header-url > a {
  color: white!important;
}
.no-margin-right{
  margin-right: 0px !important;
}
.dropdown-header{
  color: white!important;
}
.header-row{
  height: 75px;
}
/* .ui.grid>.column:not(.row), .ui.grid>.row>.column {
  padding-left: 1.75rem;
} */
.dropdown-bold{
  font-weight: bold;
}
a.active{
  /* font-weight: 900!important; */
}
.full-height{
  min-height:50vh;
}
div.blank > div > input{
  font: inherit !important;
  color: currentColor !important;
  border: 0 !important;
  margin: 0 !important;
  /* padding: 6px 0 7px !important; */
  padding: 0!important;
  display: block !important;
  min-width: 0 !important;
  flex-grow: 1 !important;
  box-sizing: content-box !important;
  background: none !important;
  vertical-align: middle !important;
  -webkit-tap-highlight-color: transparent !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-radius: 0px!important;
  text-align: center!important;
}
.white > div{
  color: white!important;
}
.white > i{
  color: white!important;
}
div.instruction > p {
  margin: 0px!important;
}
div.instruction {
  margin-bottom: 1rem;
}
div.read-content > p {
  margin: 0px!important;
}
div.read-content {
  margin: 1rem;
  /* max-height: 85vh;
  overflow-y: auto; */
}
form.read-mcq-radio > div.field {
  margin-bottom: 0px !important;
}
input.blank{
  font: inherit !important;
  color: currentColor !important;
  border: 0 !important;
  margin: 0 !important;
  /* padding: 6px 0 7px !important; */
  padding: 0!important;
  min-width: 0 !important;
  flex-grow: 1 !important;
  box-sizing: content-box !important;
  background: none !important;
  vertical-align: middle !important;
  -webkit-tap-highlight-color: transparent !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-radius: 0px!important;
  display: inline-block;
  text-align: center!important;
  width: 15% !important;
}
div.flow-chart-box > div.inline{
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
div.flow-chart-box{
  margin-bottom: 0px!important;
}
div.margin-bottom-child-10px > form{
  margin-bottom: 3rem;
}
div.full-width{
  width:100%important
}
div.eighty-vh{
  height: 80vh;
  max-height: 80vh;
}
img.auto-height{
  width: auto !important;
  height: inherit !important;
  max-width: auto;
  max-height: auto;
}
.sound-wave{
  width:92%;
  height: 5rem;
  background: #F5F8FF;
}
.dropdown-bold > div.text{
  font-weight: bold;
}
div.margin-top-9 > div.toggle{
  margin-top:9px;
}
.margin-top-25-impt{
  margin-top:25px!important;
}
div.max-height-85vh{
  max-height: 75vh;
  overflow-y:auto;
}
div.height-85vh{
  height:75vh;
}
.timer-header{
  padding: 2px !important;
  background-color: rgb(242, 113, 28) !important;
  color:white;
}
div.no-padding-bottom {
  padding-bottom:0rem !important;
}
.instruction-header{
  max-height: 10vh!important;
  overflow-y: auto;
}
.no-padding-top-impt{
  padding-top: 0rem !important;
}
.children-margin-bottom-5px > .container {
  margin-bottom: 1rem;
}
.children-margin-bottom-5px > div:last-child {
  margin-top: 1rem;
}
.no-padding-right{
  padding-right: 0px !important;
}
.exam-nav{
  max-height: 90.7vh;
  overflow-y:auto;
}
.test-nav > .active{
  background-color: rgb(242, 113, 28) !important;
  color:white !important;
}
.wrong-answer{
  text-decoration: line-through red 3px;
  color: red;
}
.margin-top-2-point-rem{
  margin-top:.2rem!important;
}
.padding-top-1rem{
  padding-top: 1rem !important;
}
.padding-bottom-2rem{
  margin-bottom: 1rem;
}
.tox-notification{
  /* visibility: hidden!important; */
}
.margin-top-1rem{
  margin-top: 1rem;
}
.no-padding{
  padding: 0px!important;
}
.font-small{
  font-size: .8rem;
}
div.test-nav{
  min-height: 0px!important;
}
input.min-width-200px{
  min-width: 200px!important;
}
.header-menu:hover{
  background-color:#f4873e!important;
}
.login-bg{
  height: 99.6vh;
  background-color:rgba(0, 0, 0, 0.5);
  background-image: url(https://ieltsapp.s3-ap-southeast-1.amazonaws.com/resources/new-ui/bg_1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.error-bg{
  height: 99.6vh;
  background-color:rgba(0, 0, 0, 0.5);
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://ieltsapp.s3-ap-southeast-1.amazonaws.com/resources/new-ui/bg_1.jpg);
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.footer{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100% !important;
  background-color: #f2711c;
  margin-right: 0px!important;
  margin-left: 0px!important;
  margin-top: -50px!important;
}
.foot-links{
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.footer-copyright{
  margin-top: 0px !important;
  padding-top: 0px !important;
  color: white !important;
}
.footer-margin{
  margin-bottom: 4rem!important;
}
.margin-top-half-rem{
  margin-top:1rem!important;
}
input.blank2{
  font: inherit !important;
  color: currentColor !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0!important;
  min-width: 0 !important;
  box-sizing: content-box !important;
  background: none !important;
  vertical-align: middle !important;
  -webkit-tap-highlight-color: transparent !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-radius: 0px!important;
  display: inline-block;
  text-align: center!important;
  width: auto!important;
}
.blank2 > div > input {
  font: inherit !important;
  color: currentColor !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0!important;
  min-width: 0 !important;
  box-sizing: content-box !important;
  background: none !important;
  vertical-align: middle !important;
  -webkit-tap-highlight-color: transparent !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-radius: 0px!important;
  display: inline-block;
  text-align: center!important;
  width: auto!important;
}
.max-height-60{
  max-height:60vh;
  overflow-y: scroll;
}
.review-max-height-80{
  max-height:70vh!important;
}
.listen-rev-full-height{
  height: 65vh!important;
}
.allow-overflow-y{
  overflow-y: auto;
}
.full-height-100{
  height: 100% !important;
}
.listening-exam-full-height{
  height:90vh;
}
div.listen-max-height-85vh{
  max-height: 85vh;
  overflow-y:auto;
}
div.listen-height-85vh{
  height:85vh;
}
.overflow-y-auto > .ui.tab.active{
  max-height: 85vh!important;
  overflow-y: auto!important;
}
.overflow-y-auto{
  max-height: 85vh!important;
  overflow-y: auto!important;
}
.footer-margin-8{
  margin-bottom: 8rem!important;
}
.speaking-height-85vh{
  max-height: 70vh!important;
  height: 70vh!important;
}
.overflow-y-auto-only{
  overflow-y:auto;
}
.speaking-title {
  position: absolute!important;
  z-index: 2;
  margin-top: 1rem!important;
  margin-left: 1rem!important;
}
.mock-speaking-test-btn{
  position: absolute!important;
  z-index: 2;
  top: 0px;
  right: 0px;
  margin-top: 1rem!important;
  margin-right: 1rem!important;
}
hr.speaking{
  background-color: rgba(224, 224, 224, 1);
}
.nonstudenttakenexam{
  margin-bottom:.25rem!important;
}
div.flow-chart-box p {
  margin-top: .25rem!important;
  margin-bottom: .25rem!important;
}