@font-face {
	font-family: 'Roboto', sans-serif;
	src: url('../fonts/Roboto-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto', sans-serif;
	src: url('../fonts/Roboto-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto', sans-serif;
	src: url('../fonts/Roboto-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto', sans-serif;
	src: url('../fonts/Roboto-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto', sans-serif;
	src: url('../fonts/Roboto-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto', sans-serif;
	src: url('../fonts/Roboto-Thin.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica', sans-serif;
	src: url('../fonts/Helvetica.otf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica', sans-serif;
	src: url('../fonts/Helvetica-Bold.otf') format('truetype');
	font-weight: 700;
	font-style: normal;
}